import {
  formatResponse,
  getLoanById,
  getPropertyOriginalValuesByLoanId
} from "../create-loan/create-loan.action";
import {
  updateLoanDetailsInRedux,
  updatePropertyDetailsInRedux
} from "../stores/loanAndPropertyDetails/loanAndPropertyDetails.action";
import {
  formatLoanDetailsResponse,
  formatPropertyResponse
} from "../create-loan/CreateLoan";
import {
  getPropertyDetails,
  setLoanByIdFailed,
  setLoanByIdSuccess
} from "../create-loan/create-loan.reducer";
import { createAndUpdateComments } from "../stores/budgetReview/budgetReview.action";
import { getUsername } from "../assetManagement/AssetManagement.utils";

export interface BudgetLineItem {
  budgetLineItemId: number;
  unit: string;
  item: string;
  description: string;
  financedBudget: string;
  budget: string;
  isMoreActionsVisible: boolean;
  isDescEditMode: boolean;
  budgetDocumentId: number;
  commentThreadId: string;
  comments?: any[]; // This will be added dynamically
}

export const updateFieldName = (data: any[]): any[] => {
  return data.map(({ externalDocumentId, ...rest }) => ({
    DocumentId: externalDocumentId,
    ...rest
  }));
};

// Helper function to get assigned user
export const getAssignedBy = (
  tasks: any[],
  taskName: string,
  userId: string
): string => {
  const assignedBy = tasks?.[0]?.assignedTasks.find((task: any) =>
    [taskName].includes(task.taskName)
  )?.assignedBy;
  return !assignedBy || ["UnAssigned", "None"].includes(assignedBy)
    ? userId
    : assignedBy;
};

// Helper function to build task object
export const buildTaskObject = (
  taskName: string,
  assignedBy: string,
  assigneePartyId: string,
  taskStatus: string,
  taskId: string,
  comment: string
) => {
  return {
    op: "replace",
    taskName,
    assignedBy,
    assigneePartyId,
    taskStatus,
    taskId,
    comment
  };
};

// Main function to handle common update logic

export const fetchCommentDetails = async (
  lineItems: BudgetLineItem[]
): Promise<BudgetLineItem[]> => {
  // Map over the array and return a new array with comments
  const updatedItems = await Promise.all(
    lineItems.map(async (item) => {
      // Only fetch comments if commentThreadId is present
      if (item.commentThreadId) {
        try {
          // Replace this URL with the actual API endpoint
          // const response = await axios.get(
          //   `/api/comments/${item.commentThreadId}`
          // );

          // Assuming the response contains an array of comments
          const comments = ["response.data"];

          // Return the updated item with the comments field
          return {
            ...item,
            comments // Add the comments field to the item
          };
        } catch (error) {
          console.error("Error fetching comments:", error);
          // In case of error, return the item without modifying it
          return { ...item, comments: [] }; // Default to empty comments if error occurs
        }
      } else {
        // If no commentThreadId, return the item as is
        return { ...item, comments: [] };
      }
    })
  );

  return updatedItems;
};

export const getPropDataByLoanID = async (
  loanId: string,
  loanStage: string,
  loanType: string,
  dispatch: any
) => {
  getPropertyOriginalValuesByLoanId(loanId, loanStage)
    .then((resp: any) => {
      updatePropertyDetailsInRedux(resp.data, dispatch);
      const formattedPropertyData = formatPropertyResponse(
        resp.data,
        loanStage,
        loanType
      );
      dispatch(
        getPropertyDetails({
          propertyDetails: formattedPropertyData,
          thirtyYearLoanPropertyDetails: formattedPropertyData
        })
      );
    })
    .catch((e) => {
      console.error(e);
      // dispatch({
      //   type: GET_PROPERTY_FAILED,
      //   error: {
      //     message: e.message
      //   }
      // });
    });
};

export const handleAddCommentProceed = async (
  lineItemsList: any[],
  docsId: string,
  comment: string,
  row: any,
  comments: any[] = []
) => {
  try {
    let chatId = await createAndUpdateComments(
      row.commentThreadId,
      docsId,
      comment,
      row.budgetLineItemId
    );
    const newCommnets = [...comments];
    newCommnets.push({
      createdAt: new Date(),
      message: comment,
      firstName: getUsername(),
      lastName: ""
    });
    let lineItemsListTemp = lineItemsList.map((item: any) => {
      if (item.budgetLineItemId === row.budgetLineItemId) {
        return { ...item, commentThreadId: chatId, comments: newCommnets };
      }
      return item;
    });
    let newList = [...lineItemsListTemp];
    return Promise.resolve(newList);
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
};

export const getDataByLoanID = async (
  loanId: string,
  loanStage: string,
  dispatch: any
) => {
  try {
    getLoanById(loanId, loanStage)
      .then(async (response: any) => {
        // eslint-disable-next-line no-shadow
        const loanDetails = formatResponse(response.data);
        updateLoanDetailsInRedux(loanDetails, dispatch); // credit-eval reducer

        const formattedLoanData = formatLoanDetailsResponse(
          response.data,
          loanStage,
          null
        );
        dispatch(
          setLoanByIdSuccess({
            loan: formattedLoanData,
            keepTab: true
          })
        );
      })
      .catch((e: any) => {
        console.error(e);
        dispatch(setLoanByIdFailed());
      });
  } catch (error) {
    console.error(error);
  }
};
