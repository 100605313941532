import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../stores/rootReducer";
import {
  DrawRolesEnum,
  URLParamsType,
  downloadDocsType
} from "../utils/constants";
import {
  hideLoader,
  showLoader
} from "../stores/loaderAndException/loaderAndException.action";
import { taskNames } from "../stores/tasks/Tasks.action";
// @ts-ignore
import { InspectionBudget } from "@toorak/budget-reconcile-sdk";
import { BudgetReviewStore } from "../stores/budgetReview/budgetReview.interface";
import { usePolling } from "../custom-hooks/usePolling";
import { apiUrlHost } from "../stores/servicerOnboarding/servicerOnboarding.action";
import { isOrigUser, isRole } from "../utils/AccessManagement";
import {
  downloadDrawDocs,
  getBudgetReconcileDetails,
  getDrawDetailsByDrawId,
  getInspectionReviewDetails,
  saveReviewAndReconcileStatus,
  updateBudgetApprovedAmount,
  updateBudgetReconcileButtonAction,
  startReconcileAPI,
  updateInspectionReview,
  getBudgetReconcileReports,
  getReconcileItemHistory,
  getBudgetReconcileCommentsList,
  createAndUpdateIRComments,
  getIRandBRHistory,
  redoReconcileHandler,
  exportDiscussions,
  postDrawStageHistory,
  updateDrawDetails,
  getAllBudgetInspectionComments,
  updateIRPredictionStatus
} from "../stores/drawRequest/drawRequest.action";
import {
  DrawObj,
  DrawRequestStore
} from "../stores/drawRequest/drawRequest.interface";
import { getConfig } from "../config/config";
import { downloadMulipleDrawDocs } from "./DocumentsInNewTab";
import {
  EXCEL_FILE_TYPE,
  PDF_FILE_TYPE
} from "../create-loan/ttf-review/constants";
import { downloadFileForTTF } from "../stores/TapeToFile/TapeToFile.action";
import { getChatsList } from "../stores/commentsSection/comment.action";
import { getCookie } from "../utils/cookies";
import Cookies from "js-cookie";
import { DrawStage } from "@toorak/tc-common-fe-sdk";
import { sanitizeCurrency } from "../utils/formatChecks";
import { ExtractionProcessState } from "../budget-review/BudgetReview.constant";
import { calculateNetAmount } from "../assetManagement/DrawRequest/DrawDataLeftPanel/DrawAmountDetails";
import { sanitizeStringToNum } from "../assetManagement/AssetManagement.utils";
import { updateBudgetReview } from "../stores/budgetReview/budgetReview.action";
import { DocumentViewer } from "../create-loan/ttf-review/document-review/document-viewer/DocumentViewer";

export const InspectionReviewWrapper = () => {
  const [, setDrawDocsDownload] = useState<any[]>([]);
  const [showNoDocs] = useState<boolean>(false);
  const [isManualEntry, setIsManualEntry] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { loanId, loanStage, loanType } = useParams<URLParamsType>() as any;
  const [commentsList, setCommentsList] = useState<any[]>([]);
  const firstName = Cookies.get("firstName");
  const lastName = Cookies.get("lastName");
  const [fieldsData, setFieldsData] = useState<any[]>([]);
  const showBudgetReview = getConfig().environment === 'qa';
  // useEffect(() => {
  //   if (loanType && loanStage && loanId && loanType !== "BridgeLoan") {
  //     navigate(`/internal/loan/createLoan/${loanType}/${loanStage}/${loanId}`);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loanType, loanStage, loanId]);
  const {
    selectedBudgetDoc,
    isLineItemsEditDisable,
    budgetApprovalHistory,
    budgetStatusHistory,
    budgetReviewHeader,
    budgetButtonInfo,
    unitVal,
    totalBudget,
    lineItemsList,
    totalfinancedBudget,
    budgetReviewResponse
  } = useSelector<RootState, BudgetReviewStore>(
    (state: { budgetReviewStore: BudgetReviewStore }) => state.budgetReviewStore
  );
  const { startPolling, stopPolling } = usePolling();
  const isOrig = isOrigUser();
  const { drawId } = useParams<any>();
  let {
    drawDetails,
    drawTaggedDocs,
    inspectionReviewData,
    propType,
    budgetReconcileData,
    budgetReconcileReports,
    reconcileItemHistory,
    selectedBankDetails,
    budgetReviewComments,
    inspectionReviewComments
  } = useSelector<RootState, DrawRequestStore>(
    (state) => state.drawRequestStore
  );
  const [firstSelectedPropIdSet, setFirstSelectedPropIdSet] = useState(false);

  const drawDiscussionsHistory: any[] = useSelector<RootState, any>(
    (state) => state.drawRequestStore.drawDiscussions
  );
  useEffect(() => {
    if (!drawId) return;
    getCommentsDataAndBudgetData(drawId);
    // eslint-disable-next-line
  }, [drawId]);

  const getBothHistory = async () => {
    if (!drawId) return;
    let reqBody = {
      resourceIds: [drawId],
      resourceTypes: ["DRAW"],
      taskNames: [
        taskNames.TAG,
        taskNames.BUDGET_RECONCILE,
        taskNames.INSPECTION_REVIEW
      ]
    };
    try {
      const history = await getIRandBRHistory(reqBody);
      return Promise.resolve(history);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    if (!drawId) return;
    dispatch(getDrawDetailsByDrawId(drawId, isOrig, true));
  }, [drawId, dispatch, isOrig]);

  useEffect(() => {
    // if (!lineItemsList.length) return;
    setFieldsData(lineItemsList);
  }, [lineItemsList]);

  useEffect(() => {
    // if (
    //   firstSelectedPropIdSet ||
    //   !(budgetReviewHeader?.selectedPropId && budgetReviewResponse?.status)
    // )
    //   return;
    // if (!lineItemsList.length) return; //if there is no lineitems, dont update
    let tempBudgetInfo = {
      rehabBudget: sanitizeCurrency(`${totalBudget}`),
      financedBudgetAmount: sanitizeCurrency(`${totalfinancedBudget}`)
    };
    if (!budgetReviewResponse?.status) return;
    dispatch(
      updateBudgetReview(
        fieldsData,
        budgetReviewResponse,
        tempBudgetInfo,
        budgetReviewResponse?.status,
        budgetReviewHeader
      )
    );
    // if (!firstSelectedPropIdSet) {
    //   setFirstSelectedPropIdSet(true);
    // }
    // eslint-disable-next-line
  }, [dispatch, budgetReviewResponse, budgetReviewHeader.selectedPropId]);

  useEffect(() => {
    if (!loanId) return;
    if (!drawId) return;
    getInspectionReviewDetails(loanId, drawId, dispatch);
  }, [drawId, loanId, isOrig]);

  const getDrawDocsCB = async (docIds: any) => {
    try {
      if (!docIds) return;
      if (!drawId) return;
      const response: any = await downloadDrawDocs(
        [docIds],
        drawId,
        isOrig,
        drawDetails.originatorId
      );
      setDrawDocsDownload(response);
      return Promise.resolve(response);
    } catch (e) {
      dispatch(hideLoader());
      return Promise.reject();
    }
  };

  async function updateInspectionLineItems(
    updatedData: any[],
    budgetTotalInfo?: any
  ) {
    if (!drawId) return;
    const reqBody = {
      ...inspectionReviewData,
      lineItems: [...updatedData]
    };

    try {
      const response = await updateInspectionReview(drawId, reqBody, dispatch);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function getCommentsDataAndBudgetData(drawId: string) {
    dispatch(showLoader());
    let resp = await getBudgetReconcileCommentsList(`${drawId}`);
    setCommentsList(resp);
    dispatch(hideLoader());
  }

  const role = isRole(DrawRolesEnum.DRAW_MANAGER)
    ? DrawRolesEnum.DRAW_MANAGER
    : DrawRolesEnum.DRAW_MEMBER;

  const downloadFromServer = async (
    cacheKey: any,
    setDownloadProgress: any,
    setCurrentRequest: any
  ) => {
    const ourRequest = axios.CancelToken.source();
    setCurrentRequest(ourRequest);
    let downloadURL = "";
    if (cacheKey) {
      const responseData: any = await getDrawDocsCB(Number(cacheKey));
      downloadURL = responseData?.data?.downloadLinks[0]?.url;
    }
    if (!downloadURL) return null;
    let response;
    response = await axios({
      url: downloadURL,
      cancelToken: ourRequest.token,
      responseType: "blob",
      onDownloadProgress(progressEvent) {
        if (progressEvent?.total) {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setDownloadProgress(progress);
        }
      }
    });
    const { data } = response;

    return data;
  };
  const onClickOpenInNewTab = (cacheKey: string, name: string) => {
    window.open(
      `${
        getConfig().redirectUrl
      }internal/loan/createLoan/${loanType}/${loanStage}/${loanId}/documentViewer/${cacheKey}/${name}`
    );
  };

  const fetchPresignedURL = async (id: string) => {
    const responseData: any =
      drawId &&
      (await downloadDrawDocs([id], drawId, isOrig, drawDetails.originatorId));
    const url = responseData?.data?.downloadLinks[0]?.url;
    if (!url) return null;
    return url;
  };

  const budgetReconcileButtonAction = async (payload: any) => {
    try {
      if (drawId) {
        await updateBudgetReconcileButtonAction(drawId, payload, dispatch);
      }
    } catch (err) {
      console.log("error : budgetReconcileButtonAction", err);
    }
  };

  const saveBudgetApprovedAmount = async (payload: any, status: any) => {
    try {
      if (drawId) {
        const taskPayload = payload[0]?.taskPayload;
        delete payload[0].taskPayload;
        await updateBudgetApprovedAmount(drawId, payload, dispatch);
        if (status === "Pending") {
          await updateReviewAndReconcileStatus(taskPayload, undefined);
        }
      }
    } catch (err) {
      console.log("error : saveBudgetApprovedAmount", err);
    }
  };

  const getBudgetReconcileData = async () => {
    try {
      if (drawId) {
        const res = await getBudgetReconcileDetails(drawId, dispatch, false);
        return res;
      }
    } catch (err) {
      console.log("error : getBudgetReconcileData", err);
      return [];
    }
  };

  const getBudgetReconcileReportsDocs = async () => {
    try {
      if (drawId) {
        const res = await getBudgetReconcileReports(
          drawId,
          drawDetails.toorakLoanId,
          dispatch
        );
        return { ...res, drawId };
      }
    } catch (err) {
      console.log("error : getBudgetReconcileReportsDocs", err);
    }
    return {};
  };

  const updateReviewAndReconcileStatus = async (
    payload: any,
    operation: any
  ) => {
    try {
      if (drawId) {
        await saveReviewAndReconcileStatus(
          drawId,
          payload,
          dispatch,
          operation
        );
      }
    } catch (err) {
      console.log("error : getBudgetReconcileData  ", err);
      return err;
    }
  };

  const handleDiscussionsSave = (discussionChat: any) => {
    let tempDiscussionHistory = JSON.parse(
      JSON.stringify(drawDiscussionsHistory)
    );
    let discObj = {
      userName: firstName + " " + lastName,
      text: JSON.stringify(discussionChat),
      role
    };
    dispatch(
      exportDiscussions(
        discObj,
        drawId,
        false,
        tempDiscussionHistory,
        drawDetails.originatorId
      )
    );
  };

  const handleDrawStatusChange = async (stage: any, reason: any) => {
    try {
      await dispatch(
        postDrawStageHistory(
          drawId,
          "Hold",
          role,
          drawDetails?.originatorId,
          reason
        )
      );
    } catch (err) {
      console.log("error occured in  handleDrawStatusChange", err);
    }
  };

  const handledApproveMismatch = async (
    chat: any,
    amount: any,
    discussionObj: any,
    approveMoreAmount: any
  ) => {
    try {
      if (!drawId) return;
      if (!approveMoreAmount) {
        handleDiscussionsSave(discussionObj);
        await dispatch(
          postDrawStageHistory(
            drawId,
            "Originator Pending",
            role,
            drawDetails?.originatorId,
            chat
          )
        );
      }
      const drawObj: DrawObj = JSON.parse(JSON.stringify(drawDetails));
      drawObj.drawAmountDetails.drawAmount = amount;
      drawObj.drawAmountDetails.comments = chat;
      drawObj.stage = DrawStage.originatorPending;
      const netFundsVal: any = sanitizeStringToNum(
        calculateNetAmount(drawObj.drawAmountDetails)
      );
      drawObj.drawAmountDetails.netFundsToBorrower = netFundsVal;
      await dispatch(
        updateDrawDetails(
          drawObj,
          selectedBankDetails,
          drawId,
          isOrig,
          drawObj.stage,
          drawObj.originatorId,
          true
        )
      );
    } catch (err) {
      console.log(`error : handledApproveMismatch: ${err}`);
    }
  };

  const redoReconcile = async () => {
    try {
      if (drawId) {
        await redoReconcileHandler(drawId, dispatch);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const startReconcile = async () => {
    try {
      if (!drawId) return;
      await startReconcileAPI(drawId);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleReportDocs = async (docId: any, name: any, isDraw: any) => {
    try {
      if (!drawId) return;
      const fileType = name.split(".").pop();
      const tcDoc = !isDraw ? "TC" : "DRAW";
      if (EXCEL_FILE_TYPE.includes(fileType.toLowerCase())) {
        if (isDraw) {
          await downloadMulipleDrawDocs(
            drawId,
            docId,
            downloadDocsType.download,
            name,
            dispatch
          );
        } else {
          await downloadFileForTTF(
            name,
            docId,
            drawDetails.toorakLoanId,
            "POST-postClose",
            dispatch
          );
        }
      } else if (PDF_FILE_TYPE.includes(fileType.toLowerCase())) {
        let newWindow: any = window.open(
          `${getConfig().redirectUrl}drawDetails/${
            drawDetails.toorakLoanId
          }/${drawId}/budgetReconciliation/documentInNewTab/${tcDoc}/${docId}/${name}`
        );
        var cookieName = "orig_id";
        var cookieValue = getCookie("org_id");
        newWindow.document.cookie = cookieName + "=" + cookieValue + "; path=/";
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddCommentProceed = async (row: any, comment: string) => {
    if (!drawId) return;
    try {
      let chatId = await createAndUpdateIRComments(
        row.reconcileCommentDiscussionId,
        drawId,
        comment,
        row.budgetLineItemsId
      );
      return Promise.resolve(chatId);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  };

  const getCommentsData = async (chatId: any) => {
    try {
      let data = await getChatsList(chatId);
      return Promise.resolve(data);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  };

  const getLineItemHistory = async (itemId: any) => {
    try {
      await getReconcileItemHistory(itemId, dispatch);
    } catch (err) {}
  };

  const getInspectionData = () => {
    if (!loanId) return;
    if (!drawId) return;
    getInspectionReviewDetails(loanId, drawId, dispatch);
  };

  const getBudgetAndInspectionComments = async (chatIds: any) => {
    try {
      await getAllBudgetInspectionComments(
        chatIds,
        inspectionReviewData?.lineItems,
        dispatch
      );
      return [];
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  function handleManualEntry(
    extractionData: any,
    handleManualEntryCallback: any,
    selectedDoc: any
  ) {
    let externalDocumentId = "";
    const index = extractionData.findIndex(
      (item: any) => item.externalDocumentId == `DRAW-${selectedDoc.docsId}`
    );

    // If the doc is found, update the status to "IN PROCESS"

    if (index !== -1) {
      extractionData[index].status = "REVIEW_IN_PROGRESS";
      extractionData[index].processState =
        ExtractionProcessState.predictionSkippedManualEntry;
      externalDocumentId = extractionData[index].externalDocumentId;
    }
    const reqBody = { ...extractionData[index] };
    if (!reqBody.extractionSourceDocumentId) {
      console.error(
        "reqBody.extractionSourceDocumentId is not valid. Can't update status"
      );
    } else {
      updateIRPredictionStatus(
        reqBody,
        reqBody.extractionSourceDocumentId,
        handleManualEntryCallback
      );
    }
  }

  return drawDetails && inspectionReviewData.totalRehabBudget ? (
    <InspectionBudget
      inspectionDocs={drawTaggedDocs?.data || []}
      showNoDocs={showNoDocs}
      drawDetails={drawDetails}
      publicURL={process.env.PUBLIC_URL}
      inspectionReviewDetails={{
        docsViewer: {
          downloadFromServerCB: downloadFromServer,
          onClickOpenInNewTabCB: onClickOpenInNewTab,
          fetchPresignedURLCB: fetchPresignedURL
        },
        chatDetails: {
          getCommentsDataCB: getCommentsData,
          createAndUpdateCommentsCB: handleAddCommentProceed
        },
        statusDetails: {
          getInspectionHistoryCB: getBothHistory,
          updateReviewAndReconcileStatusCB: updateReviewAndReconcileStatus
        },
        startReconcileCB: startReconcile,
        selectedBudgetDoc,
        isLineItemsEditDisable,
        updateInspectionReviewCallback: updateInspectionLineItems,
        budgetApprovalHistory,
        BulkBudgetSum: { grandRehabBudget: null },
        budgetStatusHistory,
        budgetReviewHeader,
        budgetButtonInfo,
        unitVal,
        totalBudget,
        totalfinancedBudget,
        commentsList,
        lineItemsList: inspectionReviewData?.lineItems,
        unmatchedInspectionItems:
          inspectionReviewData?.unmatchedInspectionItems,
        totalRehabBudget: inspectionReviewData?.totalRehabBudget,
        getInspectionData: getInspectionData,
        propType: propType,
        usePolling: {
          startPolling,
          pollingURL: `${apiUrlHost}/ocr/extraction/${drawDetails.toorakLoanId}/documents`,
          stopPolling,
          isManualEntry,
          setIsManualEntry,
          updatePredictionStatusCB: handleManualEntry
        }
      }}
      DocumentViewerCompo={DocumentViewer}
      showBudgetReview={showBudgetReview}
      budgetReconcileButtonAction={budgetReconcileButtonAction}
      saveBudgetApprovedAmount={saveBudgetApprovedAmount}
      getBudgetItemCB={getBudgetReconcileData}
      budgetReconcileData={budgetReconcileData}
      getBudgetReconcileReportsDocs={getBudgetReconcileReportsDocs}
      budgetReconcileReports={budgetReconcileReports}
      updateReviewAndReconcileStatus={updateReviewAndReconcileStatus}
      handleReportDocs={handleReportDocs}
      getLineItemHistory={getLineItemHistory}
      reconcileItemHistory={reconcileItemHistory}
      redoReconcile={redoReconcile}
      handledApproveMismatch={handledApproveMismatch}
      getBudgetAndInspectionCommentsCB={getBudgetAndInspectionComments}
      handleDrawStatusChange={handleDrawStatusChange}
      budgetAndReconcileComments={{
        inspectionReviewComments,
        budgetReviewComments
      }}
    />
  ) : (
    <>{"loading..."}</>
  );
};
