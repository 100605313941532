import React, { useEffect, useState } from "react";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { checkUser, isILP, Roles } from "../utils/AccessManagement";
import { getConfig } from "../config/config";
import { useParams } from "react-router-dom";
import { URLParamsType } from "../utils/constants";
import { useBudgetStyles } from "../budget-review/BudgetReview.style";
import { InfoIcon } from "../icons/info";
import { useSelector } from "react-redux";
import { RootState } from "../stores/rootReducer";
import { HtmlWhiteTooltip } from "../utils/Tooltip";
import { Typography } from "@mui/material";
import { taskNames } from "../stores/tasks/Tasks.action";
import { budgetReviewStatusEnum } from "./list-view/BudgetTaskManagement";
import { DropDownCommon } from "../utils/DropDownCommon";

enum DropdownEnum {
  BudgetReview = "Budget Review"
}

export const DetailViewDropDown = (props: any) => {
  const { options } = props;
  const { loanId, loanStage, loanType } = useParams<URLParamsType>() as any;
  const { BudgetReview } = DropdownEnum;
  const classes = useBudgetStyles();
  const { tasks } = useSelector<RootState, any>((state) => state.TasksStore);

  const [isMoreActionsVisible, setIsMoreActionsVisible] =
    useState<boolean>(false);
  const [disabledList, setDisabledList] = useState<DropdownEnum[]>([]);
  const [dropdownOptions, setDropdownOptions] = useState<any[]>([]);

  useEffect(() => {
    if (!options.length) return;
    const newOptions = [...options];
    if (isILP()) {
      const newAction = {
        title: BudgetReview,
        role: [Roles.LAT],
        showInStage: [LoanStage.post, LoanStage.pre],
        hidden: true,
        loanType: ["BridgeLoan"]
      };
      newOptions.splice(1, 0, newAction);
    }

    setDropdownOptions(newOptions);
  }, [options, BudgetReview]);

  const handleActionBtnClick = async (item: any) => {
    if (disabledList.includes(item.title)) return;
    if (item.title === BudgetReview) {
      let url = `${
        getConfig().redirectUrl
      }internal/loan/createLoan/${loanType}/${loanStage}/${loanId}/budgetReview`;
      
      if (["dev", "qa"].includes(getConfig().environment)) {
        url = url + `/v2`;
      }
      window.open(url);
    }
    // Use this while adding anything later
    // if (
    //   [
    //     LoanHistoryEnum,
    //     KeyDealMetricsILP,
    //     LoanSummaryChecklist,
    //     AppraisalDashboard
    //   ].includes(item.title)
    // ) {
    //   item.onClick();
    // }
  };

  useEffect(() => {
    if (!tasks.length) {
      return setDisabledList([BudgetReview]);
    } else setDisabledList([]);
    const assignedTasks = tasks[0]?.assignedTasks || [];
    const ifBudgetReviewTaskIsReady = assignedTasks.some(
      (task: any) =>
        task.taskName === taskNames.BUDGET_REVIEW &&
        task.taskStatus !== budgetReviewStatusEnum.LoanSubmitted
    );

    if (!ifBudgetReviewTaskIsReady) {
      setDisabledList([BudgetReview]);
    }
    // eslint-disable-next-line
  }, [tasks]);

  const availableOptions = dropdownOptions.filter((ele) => {
    return (
      checkUser(ele.role) &&
      (!ele.showInStage || ele.showInStage?.includes(loanStage)) &&
      (!ele.loanType || ele.loanType?.includes(loanType))
    );
  });

  const getSpecialHTML = (title: DropdownEnum) => {
    if (!disabledList.includes(title)) return <span>{title}</span>;
    return (
      <>
        <span style={{ color: "gray" }}>{title}</span>
        <HtmlWhiteTooltip
          title={
            <>
              <Typography style={{ fontSize: 12, fontWeight: 400 }}>
                Document(s){" "}
              </Typography>
              <Typography style={{ fontSize: 12, fontWeight: 400 }}>
                unavailable{" "}
              </Typography>
              <Typography style={{ fontSize: 12, fontWeight: 400 }}>
                to proceed.
              </Typography>
            </>
          }
          placement="top"
          arrow
        >
          <div
            className={`${classes.infoIconContainer} makeStyles-infoIconContainer-100`}
            title="tooltipText"
          >
            <InfoIcon />
          </div>
        </HtmlWhiteTooltip>
      </>
    );
  };

  if (!availableOptions?.length) {
    return <></>;
  }

  return (
    <>
      <DropDownCommon
        availableOptions={availableOptions}
        setIsMoreActionsVisible={setIsMoreActionsVisible}
        isMoreActionsVisible={isMoreActionsVisible}
        handleActionBtnClick={handleActionBtnClick}
        displayIcon={"MoreVert"}
        disabledList={[]}
        styleMenuItem={{ minWidth: 205 }}
        customMenuStyle={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 10
        }}
        specialOptionsHtml={(title: DropdownEnum) => getSpecialHTML(title)}
      />
    </>
  );
};
