import { publicClient } from "@toorak/tc-common-fe-sdk";
import { ExtractionProcessState } from "../../budget-review/BudgetReview.constant";
import { sortArrayByUpdatedOn } from "../../budget-review/BudgetReview.utils";
import { budgetReviewStatusEnum } from "../../ilp/list-view/BudgetTaskManagement";
// import { getTaskDetails } from "../../network/apiService";
import { getCookie } from "../../utils/cookies";
import { convertUSDToNumber, sanitizeCurrency } from "../../utils/formatChecks";
import { getChatMeta, getChatsList } from "../commentsSection/comment.action";
import { createCommentThread1 } from "../Discussions/Discussions.action";
import {
  hideLoader,
  showLoader
} from "../loaderAndException/loaderAndException.action";
import { apiUrlHost } from "../servicerOnboarding/servicerOnboarding.action";
import { getTasks, taskNames } from "../tasks/Tasks.action";
import { getPartyId } from "../WaiverRequest/WaiverRequest.action";
import {
  budgetResponse,
  budgetReviewHeaderInterface,
  BudgetTotalInfo,
  BulkBudgetSumInterface,
  LineItem
} from "./budgetReview.interface";
import {
  isUnitShown,
  setBudgetGetResponse,
  setStandardizedItems,
  updateBudgetApprovalHistory,
  updateBudgetHistory,
  updateBudgetStatus,
  updateBulkBudgetSum,
  updateBulkDocsData,
  updateLineItemList,
  updateBudgetPropData,
  updateSelectedPropDetails,
  updateTotalBudget,
  updateTotalFinancedBudget
} from "./budgetReview.reducer";
import { convertNumberToUSD } from "../../budget-review/ReviewConfig";
export function getStandardizedItems(): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/drawservice/assetmanagement/standardized-items`;
      const response = await publicClient.get(url);
      dispatch(
        setStandardizedItems(response.data.length > 0 ? response.data : [])
      );
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

export function calculateBulkBudgetSum(data: any[]) {
  const result = data.reduce(
    (acc, item) => {
      // Calculate the sum of "totalRehabBudget"
      acc.grandRehabBudget +=
        item.staticData?.documentData &&
        item.staticData?.documentData.propertyId
          ? item.extractedInfoProcessed.totalRehabBudget || 0
          : 0;

      // Check if all "areFieldsValid" values are true
      if (!item.staticData?.documentData.propertyId) {
        acc.grandFieldValidation = false;
      } else if (
        (!item.extractedInfoProcessed.areFieldsValid ||
          item.processState === ExtractionProcessState.inProgress) &&
        item.staticData?.documentData?.propertyId
      ) {
        acc.grandFieldValidation = false;
      }

      return acc;
    },
    { grandFieldValidation: true, grandRehabBudget: 0 }
  );

  return result;
}

export function getNewLineItem(item?: LineItem) {
  return {
    id: item?.id ? item.id : `tempId_${Math.random()}`,
    unit: item?.unit ?? 1,
    item: item?.item ?? "",
    description: item?.description ?? "",
    financedBudget: item?.financedBudget
      ? sanitizeCurrency(`${item.financedBudget}`)
      : "$0.00",
    budget: item ? sanitizeCurrency(`${item.budget}`) : `$0.00`,
    isMoreActionsVisible: false,
    isDescEditMode: false,
    standardizedItem: "",
    standardizedItemId: item ? item.standardizedItemId : null,
    commentId: item?.commentId
  };
}

export function getNewLineItemV2(item?: any) {
  return {
    budgetLineItemId: item?.budgetLineItemId
      ? item.budgetLineItemId
      : `tempId_${Math.random()}`,
    unit: item?.unit ?? 1,
    item: item?.item ?? "",
    description: item?.description ?? "",
    financedBudget: item?.financedBudget
      ? sanitizeCurrency(`${item.financedBudget}`)
      : "$0.00",
    budget: item ? sanitizeCurrency(`${item.budget}`) : `$0.00`,
    isMoreActionsVisible: false,
    isDescEditMode: false,
    budgetDocumentId: item?.budgetDocumentId,
    commentThreadId: item?.commentThreadId
  };
}

// export function updateLineItem(lineObject: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_LINE_ITEMS_LIST,
//       payload: lineObject
//     });
//   };
// }

export function updateLineReduxData(
  response: budgetResponse,
  dispatch: any,
  financedBudgetPercentage: number, //budgetButtonInfo
  commentsList?: any[],
  canUpdateUnit?: boolean
) {
  let lineObject: LineItem[] = [];
  let totalBudget: number = 0;
  let totalfinancedBudget: number = 0;
  var commentId: any = null;
  let isMultipleUnit = false;
  if (
    response.extractedInfoProcessed &&
    response.extractedInfoProcessed?.lineItems?.length
  ) {
    response.extractedInfoProcessed?.lineItems.forEach((item: any) => {
      const newFnancedBudget = item.budget
        ? (financedBudgetPercentage * item.budget) / 100
        : 0;
      totalBudget += item.budget;
      totalfinancedBudget += newFnancedBudget || 0;
      commentId = item.commentId
        ? item.commentId
        : commentsList
        ? commentsList?.find((comment: any) => comment.title === item.id)?.id
        : "";
      let newItem: LineItem = {
        ...item,
        ...{ commentId: commentId ? commentId : "" },
        ...{ financedBudget: newFnancedBudget }
      };
      if (item.unit != null && item.unit?.toString() !== "1") {
        isMultipleUnit = true;
      }
      lineObject.push(getNewLineItem(newItem));
    });
    if (canUpdateUnit) {
      dispatch(isUnitShown(isMultipleUnit));
    }
    dispatch(updateLineItemList(lineObject));
    dispatch(updateTotalBudget(totalBudget));
    dispatch(updateTotalFinancedBudget(totalfinancedBudget));
  } else {
    dispatch(updateTotalBudget(0));
    dispatch(updateTotalFinancedBudget(0));
  }
}

const fetchDataFromAPI = async (chatId: string) => {
  try {
    let data = await getChatsList(chatId);
    return Promise.resolve({ [chatId]: data });
  } catch (error) {
    console.error("Failed to fetch data:", error);

    return {
      [chatId]: {
        customers: [],
        chatLines: []
      }
    };
  }
};

const getCustomerNamesFromChatLines = (data: any, chatData: any) => {
  const partyIdCache: {
    [partyId: string]: {
      firstName: string;
      lastName: string;
      chatId: string;
      createdAt: string;
    };
  } = {};
  let chatId = "";
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      chatId = key;
    }
  }
  return data[chatId].chatLines.map((chatLine: any) => {
    const { partyId, lineText, chatId, updatedOn } = chatLine;

    if (partyIdCache[partyId]) {
      return chatData[chatId].push({
        ...partyIdCache[partyId],
        message: lineText,
        chatId,
        createdAt: updatedOn
      });
    }

    const customer = data[chatId].customers.find(
      (cust: any) => cust.partyId === partyId
    );

    if (customer) {
      partyIdCache[partyId] = {
        firstName: customer.firstName,
        lastName: customer.lastName,
        chatId,
        createdAt: updatedOn
      };

      return chatData[chatId].push({
        firstName: customer.firstName,
        lastName: customer.lastName,
        message: lineText,
        chatId,
        createdAt: updatedOn
      });
    } else {
      return chatData[chatId].push({
        firstName: "Unknown",
        lastName: "Unknown",
        message: lineText,
        chatId,
        createdAt: updatedOn
      });
    }
  });
};

async function getChatsDetails(lineObject: any[]) {
  let chatIds: string[] = [];
  let chatData: any = {};
  lineObject.forEach((item: any) => {
    if (item.commentThreadId) {
      chatIds.push(item.commentThreadId);
    }
  });
  const apiCalls = chatIds.map((chatId: string) => {
    chatData[chatId] = [];
    return fetchDataFromAPI(chatId);
  });

  // Step 2: Await all API results
  const allData = await Promise.all(apiCalls);

  allData?.map((chatId: any) =>
    getCustomerNamesFromChatLines(chatId, chatData)
  );
  return chatData;
}

export async function updateLineWithComment(
  lineItems: any[],
  dispatch: any,
  financedBudgetPercentage: number, //budgetButtonInfo
  commentsList?: any[],
  canUpdateUnit?: boolean
) {
  let lineObject: any[] = [];
  let totalBudget: number = 0;
  let totalfinancedBudget: number = 0;
  var commentId: any = null;
  let isMultipleUnit = false;

  if (lineItems?.length) {
    lineItems.forEach((item: any) => {
      const newFnancedBudget = item.budget
        ? (financedBudgetPercentage * item.budget) / 100
        : 0;
      const commentsData = commentsList?.find(
        (comment: any) => comment.title == item.budgetLineItemId
      );
      totalBudget += item.budget;
      totalfinancedBudget += newFnancedBudget || 0;
      commentId = item.commentThreadId
        ? item.commentThreadId
        : commentsList
        ? commentsData?.id
        : "";
      let newItem: any = {
        ...item,
        ...{ commentThreadId: commentId ?? "" },
        ...{ financedBudget: newFnancedBudget }
      };
      if (item.unit != null && item.unit?.toString() !== "1") {
        isMultipleUnit = true;
      }
      lineObject.push(getNewLineItemV2(newItem));
    });
    if (canUpdateUnit) {
      dispatch(isUnitShown(isMultipleUnit));
    }
    let commentsDetails = await getChatsDetails(lineObject);
    const updatedWithComments = lineObject.map((item) => {
      if (item.commentThreadId && commentsDetails[item.commentThreadId]) {
        return {
          ...item,
          comments: commentsDetails[item.commentThreadId]
        };
      }
      return item;
    });
    dispatch(updateLineItemList(updatedWithComments));
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    dispatch(updateTotalBudget(totalBudget));
    dispatch(updateTotalFinancedBudget(totalfinancedBudget));
  } else {
    dispatch(updateTotalBudget(0));
    dispatch(updateTotalFinancedBudget(0));
  }
}

export function getBudgetReviewData(
  documentId: number,
  commentsList: any[],
  financedBudgetPercentage: number,
  propertyDetails: any,
  handleEmptyLineItemsCB?: any
): any {
  return async (dispatch: any) => {
    try {
      // dispatch(showLoader());
      const url = `${apiUrlHost}/ocr/extraction/document/${documentId}`;
      const response = (await publicClient.get(url)).data;
      updateLineReduxData(
        response,
        dispatch,
        financedBudgetPercentage,
        commentsList,
        true
      );
      dispatch(setBudgetGetResponse(response));

      if (
        response.extractedInfoProcessed &&
        !response.extractedInfoProcessed?.lineItems?.length
      ) {
        handleEmptyLineItemsCB && handleEmptyLineItemsCB(response);
      }
      const selectProp = propertyDetails.find(
        (property: any) =>
          // eslint-disable-next-line
          property.loanPropertyOrder ==
          Number(response.staticData.documentData?.propertyId)
      );
      if (selectProp) {
        const { street_line, state, city, zipcode } =
          selectProp.propertyLocation.payload.address;
        let selectedAddress = `${street_line}, ${city}, ${state}, ${zipcode}`;
        dispatch(
          updateSelectedPropDetails({
            selectedPropInfo: selectedAddress,
            selectedPropId: Number(response.staticData.documentData?.propertyId)
          })
        );
      } else {
        dispatch(
          updateSelectedPropDetails({
            selectedPropInfo: null,
            selectedPropId: null
          })
        );
      }
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

export async function deleteLineItems(payload: any) {
  const url = `${apiUrlHost}/budget-document/delete/line-items`;
  try {
    const resp = await publicClient.delete(url, { data: payload });
    return Promise.resolve(resp);
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export function getBudgetReviewDataV2(
  commentsList: any[],
  lineItems: any[],
  financedBudgetPercentage: number,
  propertyDetails: any,
  budgetExtractionGetResponse: any
): any {
  return (dispatch: any) => {
    try {
      updateLineWithComment(
        lineItems,
        dispatch,
        financedBudgetPercentage,
        commentsList,
        true
      );
      // dispatch(setBudgetGetResponse(response));

      // if (
      //   lineItems?.length
      // ) {
      //   handleEmptyLineItemsCB && handleEmptyLineItemsCB(response);
      // }
      const selectProp = propertyDetails.find(
        (property: any) =>
          // eslint-disable-next-line
          property.loanPropertyOrder ==
          Number(
            budgetExtractionGetResponse.staticData.documentData?.propertyId
          )
      );
      if (selectProp) {
        const { street_line, state, city, zipcode } =
          selectProp.propertyLocation.payload.address;
        let selectedAddress = `${street_line}, ${city}, ${state}, ${zipcode}`;
        dispatch(
          updateSelectedPropDetails({
            selectedPropInfo: selectedAddress,
            selectedPropId: Number(
              budgetExtractionGetResponse.staticData.documentData?.propertyId
            )
          })
        );
      } else {
        dispatch(
          updateSelectedPropDetails({
            selectedPropInfo: null,
            selectedPropId: null
          })
        );
      }
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

export async function updatePredictionStatus(
  budgetReviewResponse: any,
  processState: ExtractionProcessState,
  lineItems: any[],
  dispatch: any,
  cb?: any
) {
  const reqBody = { ...budgetReviewResponse };
  reqBody.processState = processState;
  reqBody.status = "REVIEW_IN_PROGRESS";
  reqBody.extractedInfoProcessed = {
    lineItems
  };
  const url = `${apiUrlHost}/ocr/extraction/document/${budgetReviewResponse.externalDocumentId}`;
  const resp = (await publicClient.patch(url, reqBody)).data;
  dispatch(setBudgetGetResponse(resp.documentDto));
  cb && cb(resp.documentDto);
}

export function updateConsolidatedUnit(
  budgetReviewResponse: any,
  isUnit: boolean,
  cb?: any
): any {
  return async (dispatch: any) => {
    // const reqBody = { ...budgetReviewResponse };
    try {
      dispatch(showLoader());
      let reqBody = { ...budgetReviewResponse };
      let extractedInfoProcessed = {
        ...budgetReviewResponse.extractedInfoProcessed
      };
      extractedInfoProcessed.isUnit = isUnit;
      reqBody.extractedInfoProcessed = extractedInfoProcessed;

      const url = `${apiUrlHost}/ocr/extraction/document/${budgetReviewResponse.externalDocumentId}`;
      const resp = (await publicClient.patch(url, reqBody)).data;

      dispatch(isUnitShown(isUnit));

      dispatch(setBudgetGetResponse(resp.documentDto));
      dispatch(hideLoader());
      cb && cb(resp);
    } catch (e) {
      dispatch(hideLoader());
      console.log(e);
    }
  };
}

export function updateBudgetLineItems(
  lineItems: LineItem[],
  docId: string,
  updateRedux?: boolean
): any {
  return async (dispatch: any) => {
    try {
      let lineItemsReq: any[] = [];
      let areFieldsValid: boolean = true;
      // budgetReviewResponse.processState === ExtractionProcessState.inProgress
      //   ? false
      //   : true;
      lineItems
        // .filter(item => item.budget || item.item || item.standardizedItemId) //don't pass empty row
        .forEach((item: any, index: number) => {
          if (item.item.trim() === "") {
            areFieldsValid = false;
          }
          const lineItem = {
            budgetLineItemOrder: index,
            budgetLineItemId: item.budgetLineItemId,
            unit: item.unit,
            commentThreadId: item.commentThreadId,
            item: item.item,
            description: item.description,
            financedBudget: convertUSDToNumber(item.financedBudget),
            budget: convertUSDToNumber(item.budget),
            status: item.status
          };
          if (
            typeof item.budgetLineItemId === "string" &&
            item.budgetLineItemId.startsWith("tempId_")
          ) {
            delete lineItem.budgetLineItemId;
          }

          lineItemsReq.push(lineItem);
        });
      const url = `${apiUrlHost}/ocr/budget-line/${docId}`;
      const response = (await publicClient.patch(url, lineItemsReq)).data;
      // let BulkBudgetSum: BulkBudgetSumInterface = {
      //   grandFieldValidation: response.grandFieldValidation,
      //   grandRehabBudget: response.grandTotalRehabBudget
      // };
      // dispatch(updateBulkBudgetSum(BulkBudgetSum));
      updateRedux && updateReduxFromResponse(response, docId, dispatch);
    } catch (e) {
      console.error(e);
    }
  };
}

export function updateBudgetReview(
  lineItems: LineItem[],
  budgetReviewResponse: any,
  budgetButtonInfo: BudgetTotalInfo,
  status: string,
  budgetReviewHeader: budgetReviewHeaderInterface,
  financedBudgetPercentage?: number,
  cb?: any
): any {
  return async (dispatch: any) => {
    try {
      if (!budgetReviewHeader.selectedPropId) {
        return console.error("can't update without PropertyId");
      }
      let lineItemsReq: any[] = [];
      let areFieldsValid: boolean =
        budgetReviewResponse.processState === ExtractionProcessState.inProgress
          ? false
          : true;
      lineItems
        // .filter(item => item.budget || item.item || item.standardizedItemId) //don't pass empty row
        .forEach((item: any) => {
          if (item.item.trim() === "") {
            areFieldsValid = false;
          }
          const lineItem = {
            id: item.id,
            unit: item.unit,
            commentId: item.commentId,
            item: item.item,
            description: item.description,
            financedBudget: convertUSDToNumber(item.financedBudget),
            budget: convertUSDToNumber(item.budget),
            standardizedItemId: item.standardizedItemId,
            status: item.status
          };

          if (item.id.startsWith("tempId_")) {
            delete lineItem.id;
          }

          lineItemsReq.push(lineItem);
        });
      let reqBody = { ...budgetReviewResponse };
      let extractedInfoProcessed = {
        lineItems: lineItemsReq,
        areFieldsValid,
        propertyInfo: budgetReviewHeader.selectedPropInfo,
        toorakLoanId: budgetReviewResponse.toorakLoanId,
        externalDocumentId: budgetReviewResponse.externalDocumentId,
        approvedBy: budgetReviewResponse.approvedBy,
        isUnit: budgetReviewResponse.extractedInfoProcessed?.isUnit,
        totalRehabBudget: convertUSDToNumber(budgetButtonInfo.rehabBudget),
        reviewedBy: budgetReviewResponse.reviewedBy,
        primaryLoanId: budgetReviewHeader.primaryLoanId,
        financedBudget: convertUSDToNumber(
          budgetButtonInfo.financedBudgetAmount
        ),
        propertyId: budgetReviewHeader.selectedPropId
      };
      reqBody.status = status;
      reqBody.extractedInfoProcessed = extractedInfoProcessed;

      const url = `${apiUrlHost}/ocr/extraction/document/${budgetReviewResponse.externalDocumentId}`;
      const response = (await publicClient.patch(url, reqBody)).data;
      financedBudgetPercentage &&
        updateLineReduxData(
          response.documentDto,
          dispatch,
          financedBudgetPercentage
        );
      let BulkBudgetSum: BulkBudgetSumInterface = {
        grandFieldValidation: response.grandFieldValidation,
        grandRehabBudget: response.grandTotalRehabBudget
      };
      dispatch(updateBulkBudgetSum(BulkBudgetSum));
      cb && cb(response.documentDto);
    } catch (err) {
      console.error(err);
    }
  };
}

export const postLineComment = async (chatId: any, payload: any) => {
  try {
    const url = `${apiUrlHost}/chatservice/${chatId}/lines`;
    await publicClient.post(url, payload);
    return Promise.resolve(chatId);
  } catch (e) {
    console.error(e);
    return Promise.reject(chatId);
  }
};

export const getCommentsList = async (docsId: string) => {
  const comments: any = (
    await getChatMeta("DOCS", docsId, "CHAT_TOPIC", "BUDGET_REVIEW")
  ).data;
  return comments;
};

export const createAndUpdateComments = async (
  commentId: string,
  docId: string,
  commentText: string,
  title: string
) => {
  const { partyId } = getPartyId();
  const payload = {
    partyId,
    lineText: commentText,
    hasDocuments: false
  };
  let commentThreadId;
  try {
    if (commentId) {
      commentThreadId = commentId;
    } else {
      let res: any = await createCommentThread1(
        "DOCS",
        docId,
        "CHAT_TOPIC",
        "BUDGET_REVIEW",
        title
      );
      commentThreadId = res.id;
    }
    return await postLineComment(commentThreadId, payload);
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export function updateBudgetReviewBulk(reqBody: any[], cb?: any): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/ocr/extraction/document/bulkUpdate`;
      const response = (await publicClient.patch(url, reqBody)).data;
      let currDoc: any = {};
      // const newBulkData: any[] = [];

      // response.forEach((item: any) => {
      //   newBulkData.push(item.documentDto);
      //   if (item.documentDto.externalDocumentId === docId) {
      //     currDoc = item;
      //   }
      // });

      // updateLineReduxData(currDoc.documentDto, dispatch);
      let BulkBudgetSum: BulkBudgetSumInterface = {
        grandFieldValidation:
          response[response.length - 1].grandFieldValidation, //the last object is supposed to have the latest value
        grandRehabBudget: response[response.length - 1].grandTotalRehabBudget
      };
      dispatch(updateBulkBudgetSum(BulkBudgetSum));
      // dispatch({
      //   type: UPDATE_BULK_DOCS_DATA,
      //   payload: newBulkData
      // });
      cb && cb(currDoc.documentDto);
      dispatch(hideLoader());
    } catch (e) {
      console.error("An error occurred:", e);
      dispatch(hideLoader());
    }
  };
}

async function selfAssigneTask(sortedBudgetHistory: any[], response: any) {
  const { partyId } = getPartyId();

  const newBudget: any[] = [
    {
      op: "replace",
      assignedBy: partyId,
      assigneePartyId: partyId,
      taskName: sortedBudgetHistory[0].taskName,
      taskStatus: sortedBudgetHistory[0].taskStatus,
      taskId: sortedBudgetHistory[0].taskId,
      comment: "Assigned to self"
    }
  ];

  const selfAssigneeReqtBody = [
    {
      resourceId: response.resourceId,
      resourceType: response.resourceType,
      assignedTasks: newBudget
    }
  ];
  const url = `${apiUrlHost}/taskservice/tasks`;
  try {
    await publicClient.patch(url, selfAssigneeReqtBody);
    return Promise.resolve(newBudget[0]);
  } catch (e) {
    console.error(e);
  }
}

// export const getBudgetApprove = async (dispatch: any, payload: any) => {
//   getTaskDetails(payload).then((response: any) => {
//     const budgetApproveTask = response?.data[0].assignedTasks.filter(
//       (task: { taskName: string }) => task.taskName === "budgetApprove"
//     );
//     dispatch(updateBudgetApprovalHistory(budgetApproveTask));
//   });
// };

export const getBudgetHistory = (reqBody: any, loanId: string): any => {
  return async (dispatch: any) => {
    try {
      const url = `${apiUrlHost}/taskservice/tasks/audit/fetch`;
      const response = (await publicClient.post(url, reqBody)).data;

      const sortedBudgetHistory = sortArrayByUpdatedOn(
        response[0]?.budgetReview
      );
      if (response[0] && response[0].budgetApprove) {
        const sortedBudgetApprovalHistory = sortArrayByUpdatedOn(
          response[0].budgetApprove
        );

        dispatch(updateBudgetApprovalHistory(sortedBudgetApprovalHistory));
      } else {
        getTasks(
          dispatch,
          {
            resourceTypes: ["LOAN"],
            resourceIds: [loanId],
            taskNames: [
              taskNames.TAG,
              taskNames.SPLIT,
              taskNames.REVIEW,
              taskNames.RECONCILE,
              taskNames.BUDGET_REVIEW,
              taskNames.BUDGET_APPROVE
            ]
          },
          false,
          true
        );
      }
      dispatch(updateBudgetStatus(sortedBudgetHistory[0].taskStatus));
      if (
        [
          budgetReviewStatusEnum.ReadyForBudgetReview,
          budgetReviewStatusEnum.InProgress
        ].includes(
          sortedBudgetHistory[0].taskStatus as budgetReviewStatusEnum
        ) &&
        ["UnAssigned", "", null].includes(
          sortedBudgetHistory[0].assigneePartyId
        )
      ) {
        //assign to self
        let newTaskObj = await selfAssigneTask(
          sortedBudgetHistory,
          response[0]
        );
        sortedBudgetHistory.unshift(newTaskObj);
      }
      dispatch(updateBudgetHistory(sortedBudgetHistory));
      return response;
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateStatusOnDocument = async (
  loanId: string,
  status: string,
  dispatch: any,
  tasks?: any
) => {
  try {
    dispatch(showLoader());
    const userId = getCookie("person_id") || "";
    let url = `${apiUrlHost}/ocr/extraction/${loanId}/status?tags=SCOW,REBD&status=${status}`;
    if (status === budgetReviewStatusEnum.ReadyForBudgetApproval) {
      url = `${url}&reviewedBy=${userId}`;
    } else if (status === budgetReviewStatusEnum.Approved) {
      let updatedTasks: any;
      updatedTasks = JSON.parse(JSON.stringify(tasks));
      // updatedTasks[0].
      const assignedTasks = updatedTasks?.[0]?.assignedTasks.find((task: any) =>
        [taskNames.BUDGET_REVIEW].includes(task.taskName)
      );
      let reviewedBy: any;
      if (assignedTasks) {
        updatedTasks[0].assignedTasks = assignedTasks;
        if (
          ["UnAssigned", "None"].includes(
            updatedTasks[0].assignedTasks[0].assigneePartyId
          )
        ) {
          reviewedBy = getCookie("person_id");
        } else {
          reviewedBy = updatedTasks[0].assignedTasks[0].assigneePartyId;
        }
      } else {
        reviewedBy = getCookie("person_id");
      }

      url = `${url}&approvedBy=${userId}&reviewedBy=${reviewedBy}`;
    }
    const response = (await publicClient.put(url)).data;
    dispatch(hideLoader());
    return response;
  } catch (e) {
    console.error(e);
    dispatch(hideLoader());
  }
};

export const updateBudgetStatusV2 = async (
  loanId: string,
  payload: any,
  dispatch: any
) => {
  try {
    dispatch(showLoader());
    const url = `${apiUrlHost}/ocr/budget-loan/${loanId}`;
    // const payload = {
    //   budgetApprovalStatus: status
    // };
    const response = (await publicClient.patch(url, payload)).data;
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (e) {
    console.error(e);
    dispatch(hideLoader());
    return Promise.reject(e);
  }
};

export const redoBudgetReview = async (
  loanId: string,
  payload: any,
  dispatch: any
) => {
  try {
    dispatch(showLoader());
    const url = `${apiUrlHost}/ocr/budget-loan/${loanId}/redo`;

    const response = (await publicClient.post(url, payload)).data;
    dispatch(hideLoader());
    return response;
  } catch (e) {
    console.error(e);
    dispatch(hideLoader());
  }
};

export function updateStatusOnTask(
  loanId: string,
  assignedTasksObj: any[],
  budgetStatusHistory: any[],
  budgetApprovalHistory?: any[]
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/taskservice/tasks`;
      const reqBody = [
        {
          resourceId: loanId,
          resourceType: "LOAN",
          assignedTasks: assignedTasksObj
        }
      ];
      const response = (await publicClient.patch(url, reqBody)).data;
      response[0].assignedTasks.forEach((resp: any) => {
        let newBudgetHistory =
          resp.taskName === "budgetReview"
            ? [...budgetStatusHistory]
            : budgetApprovalHistory
            ? [...budgetApprovalHistory]
            : [];
        resp.updatedOn = new Date();
        newBudgetHistory.unshift(resp);
        switch (resp.taskName) {
          case "budgetReview":
            dispatch(updateBudgetStatus(resp.taskStatus));
            dispatch(updateBudgetHistory(newBudgetHistory));
            break;

          case "budgetApproval":
            if (budgetApprovalHistory) {
              dispatch(updateBudgetApprovalHistory(newBudgetHistory));
            }
            break;

          default:
            break;
        }
      });
      dispatch(hideLoader());
      return response;
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
    }
  };
}

export function getBulkBudgetReviewData(toorakLoanId: string, cb?: any): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/ocr/extraction/${toorakLoanId}/documents?tags=REBD,SCOW`;
      const response: budgetResponse[] = (await publicClient.get(url)).data;
      //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>..
      dispatch(updateBulkDocsData(response));
      cb && cb(response);

      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err);
    }
  };
}

function updateReduxFromResponse(
  response: any,
  selectedDocId: any,
  dispatch: any
) {
  dispatch(
    updateBulkBudgetSum({
      grandFieldValidation: response.validationFlag,
      grandRehabBudget: (response.grandCalculatedTotalBudget)
    })
  );
  const budgetLines = response.budgetDocumentInfoList?.find(
    (budget: any) => budget.externalDocumentId == selectedDocId
  ).budgetLineItemList;
  dispatch(updateLineItemList(budgetLines));
  dispatch(updateBudgetPropData(response.budgetDocumentInfoList));
  return budgetLines;
}

export function getBudgetData(
  loanId: string,
  selectedDocId: number,
  cb?: any
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${apiUrlHost}/ocr/budget-loan/${loanId}`;
      const response: any = (await publicClient.get(url)).data;
      // dispatch(updateLineItemList(response));
      // dispatch(
      //   updateBulkBudgetSum({
      //     grandFieldValidation: response.validationFlag,
      //     grandRehabBudget: convertNumberToUSD(
      //       response.grandCalculatedTotalBudget
      //     )
      //   })
      // );
      // const budgetLines = response.budgetDocumentInfoList?.find(
      //   (budget: any) => budget.externalDocumentId == selectedDocId
      // ).budgetLineItemList;
      // dispatch(updateLineItemList(budgetLines));
      // dispatch(updateBudgetPropData(response.budgetDocumentInfoList));
      const budgetLines = updateReduxFromResponse(
        response,
        selectedDocId,
        dispatch
      );
      cb && cb(selectedDocId, budgetLines);

      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      console.error(err);
    }
  };
}

export async function getUserDetails(payload: any) {
  try {
    const url = `${apiUrlHost}/customer/fetch`;
    const response: any = (await publicClient.post(url, payload)).data;
    return Promise.resolve(response);
  } catch (error) {
    console.error("Failed to fetch data:", error);
    return Promise.reject(error);
  }
}
