import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BudgetReviewStore } from "./budgetReview.interface";

import { budgetReviewStatusEnum } from "../../ilp/list-view/BudgetTaskManagement";

export const initBudgetErrors = {
  hasErrors: false,
  budget: false,
  // lineItem: false,
  property: false
};

export const initialState: BudgetReviewStore = {
  standardizedItems: [],
  budgetDocsList: [],
  selectedBudgetDoc: null,
  lineItemsList: [],
  budgetPropData: [],
  isLineItemsEditDisable: false,
  BulkDocsData: [],
  unitVal: "1",
  budgetStatusHistory: [],
  budgetApprovalHistory: [],
  BulkBudgetSum: {
    grandFieldValidation: false,
    grandRehabBudget: 0
  },
  budgetErrors: initBudgetErrors,
  budgetReviewHeader: {
    primaryLoanId: null,
    budgetReviewStatus: budgetReviewStatusEnum.LoanSubmitted,
    isUnitShown: false,
    selectedPropId: null,
    selectedPropInfo: null
  },
  totalBudget: 0,
  totalfinancedBudget: 0,
  budgetReviewResponse: null,
  budgetButtonInfo: {
    financedBudgetAmount: "$0.00",
    rehabBudget: "$0.00",
    financedBudgetPercentage: 0
  }
};

const budgetReviewSlice = createSlice({
  name: "budgetReview",
  initialState: initialState, // Replace with your actual initial state
  reducers: {
    setStandardizedItems: (state, action: PayloadAction<any>) => {
      state.standardizedItems = action.payload;
    },
    setBudgetDocsList: (state, action: PayloadAction<any>) => {
      state.budgetDocsList = action.payload;
    },
    setSelectedBudgetDoc: (state, action: PayloadAction<any>) => {
      state.selectedBudgetDoc = action.payload;
    },
    updateLineItemList: (state, action: PayloadAction<any>) => {
      state.lineItemsList = action.payload;
    },
    updateBudgetPropData: (state, action: PayloadAction<any>) => {
      state.budgetPropData = action.payload;
    },
    isUnitShown: (state, action: PayloadAction<boolean>) => {
      state.budgetReviewHeader.isUnitShown = action.payload;
    },
    setUnitVal: (state, action: PayloadAction<any>) => {
      state.unitVal = action.payload;
    },
    setBudgetGetResponse: (state, action: PayloadAction<any>) => {
      state.budgetReviewResponse = action.payload;
    },
    updateBudgetStatus: (state, action: PayloadAction<any>) => {
      state.budgetReviewHeader.budgetReviewStatus = action.payload;
    },
    updateBudgetPrimaryLoanId: (state, action: PayloadAction<any>) => {
      state.budgetReviewHeader.primaryLoanId = action.payload;
    },
    updateSelectedPropDetails: (state, action: PayloadAction<any>) => {
      const { selectedPropInfo, selectedPropId } = action.payload;
      state.budgetReviewHeader.selectedPropInfo = selectedPropInfo;
      state.budgetReviewHeader.selectedPropId = selectedPropId;
    },
    updateTotalFinancedBudget: (state, action: PayloadAction<any>) => {
      state.totalfinancedBudget = action.payload;
    },
    updateBulkBudgetSum: (state, action: PayloadAction<any>) => {
      state.BulkBudgetSum = action.payload;
    },
    updateBulkDocsData: (state, action: PayloadAction<any>) => {
      state.BulkDocsData = action.payload;
    },
    updateBudgetButtonInfo: (state, action: PayloadAction<any>) => {
      state.budgetButtonInfo = action.payload;
    },
    updateLineItemDisable: (state, action: PayloadAction<boolean>) => {
      state.isLineItemsEditDisable = action.payload;
    },
    updateTotalBudget: (state, action: PayloadAction<any>) => {
      state.totalBudget = action.payload;
    },
    updateBudgetHistory: (state, action: PayloadAction<any>) => {
      state.budgetStatusHistory = action.payload;
    },
    updateBudgetApprovalHistory: (state, action: PayloadAction<any>) => {
      state.budgetApprovalHistory = action.payload;
    },
    updateBudgetErrors: (state, action: PayloadAction<any>) => {
      state.budgetErrors = action.payload;
    }
  }
});

export const {
  setStandardizedItems,
  setBudgetDocsList,
  setSelectedBudgetDoc,
  updateLineItemList,
  updateBudgetPropData,
  isUnitShown,
  setUnitVal,
  setBudgetGetResponse,
  updateBudgetStatus,
  updateBudgetPrimaryLoanId,
  updateSelectedPropDetails,
  updateTotalFinancedBudget,
  updateBulkBudgetSum,
  updateBulkDocsData,
  updateBudgetButtonInfo,
  updateLineItemDisable,
  updateTotalBudget,
  updateBudgetHistory,
  updateBudgetApprovalHistory,
  updateBudgetErrors
} = budgetReviewSlice.actions;

export default budgetReviewSlice.reducer;
